<template>
<!-- 日志管理 -->
  <div>
    <el-breadcrumb separator="">
      <el-breadcrumb-item  class="sptor">日志管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 1" class="sptor isactive">登录日志</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 2" class="sptor isactive">操作日志</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="setting_main_bg">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="登录日志" name="1">
          <LoginLog v-if="activeName == 1"></LoginLog>
        </el-tab-pane>

        <el-tab-pane label="操作日志" name="2">
          <OperLog v-if="activeName == 2"></OperLog>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import OperLog from '@/components/operLog.vue'
import LoginLog from '@/components/loginLog.vue'

export default {
  components: {
    OperLog,
    LoginLog
  },
  data () {
    return {
      activeName: '1'
    }
  },
  created () {

  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
    }
  }
}
</script>

<style scoped>
.isactive >>> .el-breadcrumb__inner {
  color: #196DF7;
}
</style>
