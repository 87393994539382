<template>
<!-- 登录日志 -->
  <div>
    <div class="unit_cont_box">
      <!-- <div class="iconfont iconjia settingAdd" @click="handleUserAdd()"><span>新增</span></div> -->
        <div class="flexAC ut_top">
          <el-input v-model="apiname"  placeholder="请输入请求人员/接口名称" clearable class="contInput"></el-input>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="timePicker"
            style="margin-right: 10px;">
          </el-date-picker>
          <!-- <el-select v-model="baptype" placeholder="请求设备" class="utF setSe">
            <el-option
              v-for="(item,index) in baptypeList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
          <el-button class="settingReset" @click="handleReset()">重置</el-button>
        </div>
      </div>
    <div class="user_table">
      <el-table
        stripe
        :data="dataList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <!-- <el-table-column
          label="请求设备"
          prop="baptype">
        </el-table-column> -->
        <el-table-column
          label="请求地址"
          prop="apicode">
        </el-table-column>
        <el-table-column
          label="接口名称"
          prop="apiname">
        </el-table-column>
        <el-table-column
          label="提示信息"
          prop="errormsg">
        </el-table-column>
        <el-table-column
          label="请求类型"
          prop="apitype">
        </el-table-column>
        <el-table-column
          label="请求时间"
          prop="logtime">
        </el-table-column>
        <el-table-column
          label="请求人员"
          prop="username">
        </el-table-column>
        <el-table-column
          label="接口状态"
          prop="apistate">
          <template slot-scope="scope">
            <span v-if="scope.row.apistate == 1">成功</span>
            <span v-if="scope.row.apistate == 2">失败</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[size]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
  </div>
</template>

<script>
import { getLog } from '@/api/user.js'

export default {
  data () {
    return {
      apiname: '',
      page: 1,
      size: 10,
      total: 0,
      dataList: [],
      // baptype: '',
      // baptypeList: [],
      startTime: '',
      endTime: '',
      time: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.time.length !== 0 && this.time !== null) {
        this.startTime = this.time[0]
        this.endTime = this.time[1]
      }
      const parmas = {
        apiname: this.apiname,
        types: '1',
        stime: this.startTime,
        etime: this.endTime,
        page_index: this.page,
        page_size: this.size
      }
      getLog(parmas).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.dataList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.dataList = []
            this.total = 0
          }
        } else {
          this.dataList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.apiname = ''
      this.time = []
      this.startTime = ''
      this.endTime = ''
      this.init()
    },
  }
}
</script>

<style scoped>

</style>
